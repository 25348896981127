



















































































import { Component } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import ScenarioResponseModel from "@/application-manager/models/ScenarioResponseModel";
import AppSectionAccessMixin from "@/shared/mixins/AppSectionAccessMixin";

@Component
export default class ScenariosView extends mixins(AppSectionAccessMixin) {
  search = "";

  get headers(): Array<Record<string, any>> {
    return [
      {
        text: this.$lang("applicationManager.name"),
        align: "start",
        value: "name",
        width: "30%",
      },
      {
        text: this.$lang("applicationManager.numberOfStepsInTheScript"),
        align: "center",
        value: "script",
        sortable: false,
        width: "60%",
      },
      ...(this.hasDeleteAccess
        ? [
            {
              text: this.$lang("commonConfig.actions"),
              value: "actions",
              align: "end",
              sortable: false,
              width: "5%",
            },
          ]
        : []),
    ];
  }

  get isLoading(): boolean {
    return this.$store.state.scenarioStore.isLoading;
  }

  get scenarios(): Array<ScenarioResponseModel> {
    return this.$store.state.scenarioStore.scenarios;
  }

  created() {
    document.title = this.$lang(
      "documentTitle",
      this.$lang("applicationManager.scenario.title")
    );
  }

  async mounted() {
    await this.$store.dispatch("loadScenarios");
  }

  async handleItemDeleting(scenarioId: number) {
    await this.$store.dispatch("deleteScenario", scenarioId);
  }

  handleClickByTableRow(item: ScenarioResponseModel) {
    if (!this.hasEditAccess) {
      return;
    }

    this.$router.push({
      name: "scenario_view",
      params: {
        scenarioId: String(item.id),
      },
    });
  }

  getCountScripts(script: string | null): number {
    return script
      ? script
          .split(";")
          .filter((item) => !!item && !item.includes("//SCENARIO_FORM")).length
      : 0;
  }
}
